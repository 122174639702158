<template>
  <div
    v-if="show"
    ref="create-role-modal"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Role
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--CREATE PARENT MENU MODAL-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a class="modal__close modal__close--black" @click="$emit('close')">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">edit role</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="form__item">
              <label class="form__label">role name</label>
              <input
                type="text"
                class="input form__input form__input--lg"
                v-model="roleName"
                placeholder="Enter role name"
              />
            </div>

            <ul id="accordion">
              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#userRights"
                  aria-expanded="false"
                  aria-controls="userRights"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    user rights
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="userRights" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="d-flex form__item flex-column">
                          <label
                            class="form__label text--black text--capital mb-3"
                            >types</label
                          >
                          <template>
                            <label
                              :for="item"
                              v-for="item in getType"
                              :key="item"
                              v-bind:value="item"
                              class="has-checkbox page__body__header__label text--capital mb-3"
                              >{{ item }}
                              <input
                                type="checkbox"
                                :id="item"
                                v-if="String(item) == 'Administrator'"
                                v-model="isAdministrator"
                                class="form-"
                              />
                              <input
                                type="checkbox"
                                :id="item"
                                v-if="String(item) == 'Teller'"
                                v-model="isTeller"
                                class="form-"
                              />
                              <input
                                type="checkbox"
                                :id="item"
                                v-if="String(item) == 'Account Officer'"
                                v-model="isCreditOfficer"
                                class="form-"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </template>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-flex form__item flex-column">
                          <label
                            class="form__label text--black text--capital mb-3"
                            >access rights</label
                          >
                          <template>
                            <label
                              :for="item"
                              v-for="item in accessRightP"
                              :key="item"
                              v-bind:value="item"
                              class="has-checkbox page__body__header__label text--capital mb-3"
                              >{{ item }}
                              <input
                                type="checkbox"
                                :id="item"
                                v-model="accessRight[item]"
                                class="form-"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#permissions"
                  aria-expanded="false"
                  aria-controls="permissions"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    permissions
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="permissions" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <input
                      type="text"
                      class="input form__input"
                      placeholder="Search for permissions"
                    />

                    <ul id="accordion-child" class="permissions-child">
                      <div
                        class="p-2"
                        v-for="(row, idx) in permission"
                        :key="row.groupId"
                      >
                        <li
                          data-toggle="collapse"
                          class="sidebar-right__item"
                          :data-target="`#t${row.groupId}`"
                          aria-expanded="false"
                          :aria-controls="`t${row.groupId}`"
                        >
                          <h5
                            class="w-100 sidebar-right__link p-1 mb-0 d-flex align-items-center"
                          >
                            <ion-icon name="caret-forward-outline"></ion-icon>
                            <label
                              :for="`tc${row.groupId}`"
                              class="has-checkbox page__body__header__label text--capital ml-3 text--small text--dark"
                              >{{ row.groupName }}
                              <input
                                type="checkbox"
                                :id="`tc${row.groupId}`"
                                v-model="row.isChecked"
                                class="form-"
                                @change="onSelectedChange(row, row.isChecked)"
                              />
                              <span class="checkmark"></span>
                            </label>
                          </h5>
                        </li>
                        <div
                          :id="`t${row.groupId}`"
                          class="collapse"
                          data-parent="#accordion-child"
                        >
                          <div class="px-5 py-2">
                            <ul
                              :id="`ac${row.groupId}`"
                              v-if="
                                row &&
                                row.subPermissions &&
                                row.subPermissions.length > 0
                              "
                              class="permissions-child"
                            >
                              <div
                                class="p-2"
                                v-for="rowSecond in row.subPermissions"
                                :key="rowSecond.subGroupId"
                              >
                                <li
                                  data-toggle="collapse"
                                  class="sidebar-right__item"
                                  :data-target="`#st${rowSecond.subGroupId}`"
                                  aria-expanded="false"
                                  :aria-controls="`st${rowSecond.subGroupId}`"
                                >
                                  <h5
                                    class="w-100 sidebar-right__link p-1 mb-0 d-flex align-items-center"
                                  >
                                    <ion-icon
                                      name="caret-forward-outline"
                                    ></ion-icon>
                                    <label
                                      :for="`st${rowSecond.subGroupId}`"
                                      class="has-checkbox page__body__header__label text--capital ml-3 text--small text--dark"
                                      >{{ rowSecond.subGroupName }}
                                      <input
                                        type="checkbox"
                                        :id="`st${rowSecond.subGroupId}`"
                                        v-model="rowSecond.isChecked"
                                        class="form-"
                                        @change="
                                          onSelectedSubPermissionChange(
                                            row,
                                            rowSecond.subGroupId,
                                            rowSecond.isChecked
                                          )
                                        "
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </h5>
                                </li>
                                <div
                                  :id="`st${rowSecond.subGroupId}`"
                                  class="collapse"
                                  :data-parent="`#ac${row.groupId}`"
                                >
                                  <div class="px-5 py-2">
                                    <ul
                                      :id="`sac${rowSecond.groupId}`"
                                      v-if="
                                        rowSecond &&
                                        rowSecond.permissions &&
                                        rowSecond.permissions.length > 0
                                      "
                                    >
                                      <li
                                        v-for="rowThird in rowSecond.permissions"
                                        :key="rowThird.permissionId"
                                        class="text--capital sidebar-right__link"
                                      >
                                        <label
                                          :for="`tt${rowThird.permissionId}`"
                                          class="has-checkbox page__body__header__label text--capital text--small"
                                          >{{ rowThird.permissionName }}
                                          <input
                                            type="checkbox"
                                            :id="`tt${rowThird.permissionId}`"
                                            class="form-"
                                            v-model="rowThird.isChecked"
                                            @change="
                                              onSelectedPermissionsInSubPermissionChange(
                                                row,
                                                rowSecond.subGroupId,
                                                rowThird.permissionId,
                                                rowThird.isChecked
                                              )
                                            "
                                          />
                                          <span class="checkmark"></span>
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <!-- <template v-if="row && row.groupPermissions && row.groupPermissions.length > 0">
                              <li v-for="rowLast in row.groupPermissions" :key="rowLast.permissionId" class="text--capital move-i sidebar-right__link">
                                <label
                                  :for="rowLast.permissionId"
                                  class="has-checkbox page__body__header__label text--capital text--small">
                                  {{ rowLast.permissionName}}
                                  <input type="checkbox" :id="rowLast.permissionId" class="form-"  v-model="rowLast.isChecked"/>
                                  <span class="checkmark"></span>
                                </label>
                              </li>
                              </template>  -->
                            </ul>
                            <ul
                              :id="`ac${row.groupId}`"
                              v-if="
                                row &&
                                row.groupPermissions &&
                                row.groupPermissions.length > 0
                              "
                              class="permissions-child"
                            >
                              <div class="">
                                <li
                                  v-for="(rowLast, i) in row.groupPermissions"
                                  :key="rowLast.permissionId"
                                  class="text--capital move-i sidebar-right__link"
                                >
                                  <label
                                    :for="`p${i + 1}-${rowLast.permissionId}`"
                                    class="has-checkbox page__body__header__label text--capital text--small"
                                  >
                                    {{ rowLast.permissionName }}
                                    <input
                                      type="checkbox"
                                      :id="`p${i + 1}-${rowLast.permissionId}`"
                                      class="form-"
                                      v-model="rowLast.isChecked"
                                      @change="
                                        onGroupPermissionChange(
                                          row,
                                          rowLast,
                                          idx
                                        )
                                      "
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </li>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="modal__accordion">
                <li
                  data-toggle="collapse"
                  class="sidebar-right__item modal__accordion--toggle"
                  data-target="#notes"
                  aria-expanded="false"
                  aria-controls="notes"
                >
                  <h5
                    class="w-100 mb-0 d-flex align-items-center justify-content-between"
                  >
                    notes
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </h5>
                </li>
                <div id="notes" class="collapse" data-parent="#accordion">
                  <div class="pt-5">
                    <div class="form__item form__item--last">
                      <label
                        class="form__label text--regular-alt text--bold text--black"
                        >notes</label
                      >
                      <textarea
                        id=""
                        cols="5"
                        rows="5"
                        class="input form__input"
                        v-model="notes"
                        placeholder="Notes"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
            <div class="form__item">
              <div class="form__action">
                <button
                  id="save-changes"
                  type="button"
                  class="button form__button form__button--lg w-30 save-changes"
                  @click="confirmSave()"
                >
                  {{ process ? "Saving..." : "save changes" }}
                </button>
                <a
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import ApiService from "@/core/services/access.service";
import { GET_MENU } from "@/core/services/store/types";
export default {
  name: "EditRole",
  components: {},
  props: {
    show: Boolean,
    close: Function,
    singleRole: Object,
    getType: Array,
    accessRightP: Array,
    permissionP: Array,
  },
  data() {
    return {
      roleName: "",
      roleKey: "",
      isAdministrator: true,
      isCreditOfficer: false,
      isTeller: false,
      accessRight: {},
      notes: "",
      permission: [],
      confirm: false,
      process: false,
    };
  },
  methods: {
    getMenu() {
      this.$store.dispatch(GET_MENU);
    },
    resetWindow: function () {
      // Object.assign(this.$data, initialState());
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
    getAllPermissions() {
      const arr1 = this.permissionP;
      const arr2 = this.singleRole.permission;
      let newArr = arr1.reduce(
        (acc, eachArr2Elem) => {
          if (
            arr2.findIndex(
              (eachArr1Elem) =>
                eachArr1Elem.groupId === eachArr2Elem.groupId &&
                eachArr1Elem.groupName === eachArr2Elem.groupName
            ) === -1
          ) {
            acc.push(eachArr2Elem);
          }
          return acc;
        },
        [...arr2]
      );
      this.permission = newArr.filter((x) => x.groupName !== "General");
    },
    onSelectedSubPermissionChange(item, subPermissionId, state) {
      // First we get the permission, sub permission groupId and the state(i.e true or false)
      // Then we map each sub permission of that permission and check if the group id we sent
      // matches any group id in the list of the permission sub permissions.
      for (let sub of item.subPermissions) {
        if (sub.subGroupId === subPermissionId) {
          // Then we set that sub permission to the `state` and the permissions under that sub
          // permission to the `state
          sub.isChecked = state;

          if (sub.permissions.length > 0) {
            for (let subPerm of sub.permissions) {
              subPerm.isChecked = state;
            }
          }
        }
      }
      this.checkSubPermissionsOfPermission(item, state);
    },
    checkSubPermissionsOfPermission(item, state) {
      // If the `state` is true we set the parent permission to the state
      if (state) {
        item.isChecked = state;
      } else {
        // else we map the list sub permissions of that parent permission and check if
        // any of the sub permissions `state` is true for that parent permission
        // if true we set the parent permission `state` as true else we set it to false
        for (let sub of item.subPermissions) {
          if (sub.isChecked) {
            item.isChecked = true;
            return;
          } else {
            item.isChecked = state;
          }
        }
      }
    },
    onSelectedPermissionsInSubPermissionChange(
      permission,
      subPermissionId,
      permissionInSubPermissionId,
      state
    ) {
      // First we get the permission, sub permission groupId and the state(i.e true or false)
      // Then we map each sub permission of that permission and check if the group id we sent
      // matches any group id in the list of the permissions sub permissions.
      for (let sub of permission.subPermissions) {
        if (sub.subGroupId === subPermissionId) {
          if (sub.permissions.length > 0) {
            // Then we map for each permission in the sub permission permissions and check
            // if the permission id we sent matches any in the list of the sub permissions permissions.
            for (let subPerm of sub.permissions) {
              // Then we set the permissions under that sub permission to the `state`
              if (subPerm.permissionId === permissionInSubPermissionId) {
                subPerm.isChecked = state;
              }
            }
          }
        }

        this.checkPermissionsOfSubPermission(
          permission,
          subPermissionId,
          state
        );
      }
    },
    checkPermissionsOfSubPermission(permission, subPermissionId, state) {
      // If the `state` is true we set the parent permission to the `state`
      // and the sub permission with the matching group id to the `state`
      if (state) {
        for (let sub of permission.subPermissions) {
          if (sub.subGroupId === subPermissionId) {
            sub.isChecked = state;
          }
        }
        permission.isChecked = state;
      } else {
        // else we map the sub permissions of that permission and find the sub
        // permission that the group id matches
        for (let sub of permission.subPermissions) {
          if (sub.subGroupId === subPermissionId) {
            if (sub.permissions.length > 0) {
              // else we map the permissions of that sub permission and check if
              for (let subPerm of sub.permissions) {
                // any of the permissions `state` is true for that sub permission
                // if true we set the parent permission and sub permission `state` as true
                if (subPerm.isChecked) {
                  permission.isChecked = true;
                  sub.isChecked = true;
                  return;
                } else {
                  // else we set the sub permission to the `state` and
                  sub.isChecked = state;

                  // map the list of sub permissions of the parent permission and check if
                  // any of the sub permissions `state` is true for that parent permission
                  // if true we set the parent permission `state` as true else we set it to the `state`
                  for (let sub of permission.subPermissions) {
                    if (sub.isChecked) {
                      permission.isChecked = true;
                      return;
                    } else {
                      permission.isChecked = state;
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    onSelectedChange(item, state) {
      if (item.groupName.toLowerCase() !== "agent dashboard") {
        if (item.groupPermissions && item.groupPermissions.length) {
          for (let child of item.groupPermissions) {
            child.isChecked = state;
          }
        }

        if (item.subPermissions && item.subPermissions.length) {
          for (let sub of item.subPermissions) {
            sub.isChecked = state;

            if (sub.permissions.length > 0) {
              for (let subPerm of sub.permissions) {
                subPerm.isChecked = state;
              }
            }
          }
        }
      } else {
        if (state === false) {
          for (let child of item.groupPermissions) {
            child.isChecked = state;
          }
        }
      }
    },
    onGroupPermissionChange(permission, subPermission, idx) {
      if (permission.groupName.toLowerCase() === "agent dashboard") {
        for (let child of permission.groupPermissions) {
          if (child.permissionId === subPermission.permissionId) {
            child.isChecked = true;
          } else {
            child.isChecked = false;
          }
        }

        for (let child of permission.groupPermissions) {
          if (child.isChecked) {
            permission.isChecked = true;
            return;
          } else {
            permission.isChecked = false;
          }
        }
        this.permission[idx] = permission;
      } else {
        for (let child of permission.groupPermissions) {
          if (child.isChecked) {
            permission.isChecked = true;
            return;
          } else {
            permission.isChecked = false;
          }
        }

        this.permission[idx] = permission;
      }
    },
    save: function () {
      this.process = true;

      let copyOfPayload = Object.assign(this.$data);

      ApiService.put(`RoleManagement/EditRole`, copyOfPayload)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("alertSuccess", res.message);
            // this.resetWindow();
            this.$emit("close");
            this.$emit("reload");
          } else {
            this.$emit("alertError", res.message);
          }
        })
        .catch((e) => {
          this.confirm = false;
          try {
            this.process = false;
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
  },
  watch: {
    singleRole(nextProps) {
      const ob = { ...this.$data };
      var self = this;
      if (Object.keys(nextProps).length == 0) {
        self.$emit(
          "alertError",
          "There is an error from the server with this role"
        );
        self.resetWindow();
        self.$emit("close");
        return;
      }

      Object.keys(nextProps).map(function (key) {
        if (ob.hasOwnProperty(key) && nextProps.hasOwnProperty(key)) {
          self[key] = nextProps[key];
        }
      });

      this.getAllPermissions();
    },
  },
  computed: {},
  mounted() {
    this.getMenu();
  },
};
</script>
